/* eslint-disable prettier/prettier */
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import * as UISelectors from 'selectors/UISelectors';
import * as applicationSelectors from 'selectors/applicationSelectors';
import * as dataCollectionSelectors from 'selectors/dataCollectionSelectors';

import dataCollectionActions from 'actions/dataCollectionActions';
import { DataCollectionStatusPollingContent } from 'components/DataCollectionStatusPollingContent/DataCollectionStatusPollingContent';
import Spinner from 'components/Spinner/Spinner';
import View from 'components/View/View';
import { injectIntl } from 'react-intl';
import * as clientSelectors from 'selectors/clientSelectors';

const DataCollectionStatusPolling = ({
  isSpinnerLoading,
  applicationId,
  clientId,
  pollDataCollectionStatusAndEvents,
  isDataCollectionSuccess,
  isDataCollectionPending,
  selectedProcesses,
  setDataCollectionPollingStatus,
}) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (applicationId) {
      pollDataCollectionStatusAndEvents({ applicationId, clientId });
    }
  }, [applicationId, clientId, pollDataCollectionStatusAndEvents]);

  return (
    <View showMenu={false} showFooter={false} showHeaderLogoOnly={true}>
      <Spinner loading={isSpinnerLoading}>
        <DataCollectionStatusPollingContent
          isSuccess={isDataCollectionSuccess}
          isInProgress={isDataCollectionPending}
          selectedProcesses={selectedProcesses}
          setDataCollectionPollingStatus={setDataCollectionPollingStatus}
        />
      </Spinner>
    </View>
  );
};

DataCollectionStatusPolling.propTypes = {
  isSpinnerLoading: PropTypes.bool,
  applicationId: PropTypes.number,
  clientId: PropTypes.number,
  isDataCollectionSuccess: PropTypes.bool,
  isDataCollectionPending: PropTypes.bool,
  selectedProcesses: PropTypes.arrayOf(PropTypes.string),
  setDataCollectionPollingStatus: PropTypes.func,
};

const mapStateToProps = (state) => ({
  isSpinnerLoading: UISelectors.hasActiveSpinners(state),
  applicationId: applicationSelectors.getApplicationId(state),
  clientId: clientSelectors.getPrimaryApplicantId(state),
  isDataCollectionSuccess:
    dataCollectionSelectors.isDataCollectionSuccess(state),
  isDataCollectionPending:
    dataCollectionSelectors.isDataCollectionPending(state),
  selectedProcesses: dataCollectionSelectors.selectedProcesses(state),
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      pollDataCollectionStatusAndEvents:
        dataCollectionActions.pollDataCollectionStatusAndEvents,
      setDataCollectionPollingStatus:
        dataCollectionActions.setDataCollectionPollingStatus,
    },
    dispatch,
  );

export default injectIntl(
  connect(mapStateToProps, mapDispatchToProps)(DataCollectionStatusPolling),
);
